/*-----------------------------------------------
|   Browser Fixing
-----------------------------------------------*/
// Firefox fixed
.firefox{
  .dropcap:first-letter{ margin-top: 0.175em; }
}


// Edge fixed
.ie,
.edge{
  .bg-holder{
    z-index: 0;
    .bg-video{
      height: auto;
      width: auto;
    }
  }
}

// IE fixed
.ie{
  .align-items-center.min-vh-100{ height: 100vh; }
  .align-items-end.min-vh-100{ height: 100vh; }
  img{ max-width: 100%; }
}
